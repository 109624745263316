.cookie-box {
  position: fixed;
  bottom: 50px;
  left: 50px;
  background: white;
  box-shadow: 50px 20px 99px #8795B54D;
  border-radius: 5px;
  padding: 25px;
  width: 350px;
  max-width: calc(100% - 20px);
  opacity: 0;
  transition: opacity .3s ease-out;
  will-change: opacity;
  z-index: 1060;

  &__actions {
    display: flex;
    column-gap: 10px;
  }

  p {
    margin: 0;
    line-height: 21px;
    font-size: 14px;
    color: $wood-smoke;

    a {
      font-size: inherit;
      text-decoration: underline;
      color: inherit;
    }
  }

  .custom-icon {
    margin-right: 19px;
    margin-bottom: 15px;
    float: left;
  }

  .button {
    margin-top: 20px;
  }

  &.show {
    opacity: 1;
  }

  @include media-breakpoint-down(lg) {
    bottom: 10px;
    left: 10px;
  }
}
