.ck.ck-editor {
  width: 100% !important;
}

.ck.ck-balloon-panel {
  z-index: 9999 !important;
}

.ck.ck-editor__main > .ck-editor__editable {
  min-height: 150px !important;
}
