.btn {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.button {
  --button-bg-color: transparent;
  --button-text-color: #{$wood-smoke};
  --button-padding-hr: #{rem(25)};

  border: none;
  background: var(--button-bg-color);
  color: var(--button-text-color);
  padding: 0 var(--button-padding-hr);
  min-width: rem(50);
  max-width: 100%;
  margin: 0;
  border-radius: rem(8);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: color .3s ease-out, background .3s ease-out;

  &:not(.button-link) {
    span {
      text-transform: uppercase;
    }

    i + span {
      text-transform: initial;
    }
  }

  i + span {
    margin-left: rem(18);
  }

  span + i {
    margin-right: 0;
    margin-left: rem(49);
  }

  &,
  &:hover,
  &:focus,
  &:focus-visible,
  &:focus-within,
  &:active {
    outline: 0;
    box-shadow: none;
    color: var(--button-text-color);
  }

  &--sm,
  &--md {
    height: rem(50);
    font-weight: 600;
  }

  &--sm {
    --button-padding-hr: #{rem(14.5)};
    font-size: rem(11);
  }

  &--md {
    font-size: rem(12);
  }

  &--lg {
    --button-padding-hr: #{rem(60)};
    height: rem(60);
    font-size: rem(18);
    font-weight: bold;
    letter-spacing: rem(2.88);
  }

  &--full-width {
    width: 100%;
  }

  &--outlined {
    border: 2px var(--button-bg-color) solid;
    color: $wood-smoke;
    background: transparent;

    &:hover {
      background: var(--button-bg-color);
      color: var(--button-text-color);
    }
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.65;
    cursor: not-allowed;
  }

  @include loader(30px, button--loading, true);
}

.button-primary {
  --button-bg-color: #{$primary};
  --button-text-color: #{$white};
}

.button-secondary {
  --button-bg-color: #{$secondary};
  --button-text-color: #{$wood-smoke};
}

.button-maroon-flush {
  --button-bg-color: #{$maroon-flush};
  --button-text-color: #{$white};
}

.button-wood-smoke {
  --button-bg-color: #{$wood-smoke};
  --button-text-color: #{$white};
}

.button-bali-hai {
  --button-bg-color: #{$bali-hai};
  --button-text-color: #{$wood-smoke};
  border-color: rgba($bali-hai, .5);

  &:hover {
    &,
    * {
      color: $white;
    }
  }
}

.button-red-ribbon {
  --button-bg-color: #{$red-ribbon};
  --button-text-color: #{$white};
}

.button-link {
  height: unset;
  padding: 0;
  background: none;
  border: 0;
  color: $bali-hai;
  text-decoration: underline;
  text-align: center;
}
