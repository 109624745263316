
.failed-info {
  display: flex;
  align-items: center;
  width: 700px;
  max-width: 100%;
  background: white;
  border-radius: 15px;
  padding: 15px;
  margin: 21px 0;
  font-weight: 600;
  font-size: 14px;

  .bi-info-circle-fill {
    font-size: 28px;
    line-height: 1;
  }
}
