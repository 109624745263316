
.btn-close {
  filter: unset;
  background: transparent;
  padding-top: 0;

  &,
  &:hover {
    opacity: 1;
    color: white;
  }
}
