@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/utilities";

$primary: #AE0F4B;
$secondary: #E4E8F2;
$danger: #FF0000;
$success: #4BB543;
$warning: #FFCD4C;
$maroon-flush: #BD245D;
$outer-space: #222727;
$alto: #D5D5D5;
$wood-smoke: #14171A;
$bali-hai: #8795B5;
$red-ribbon: #D90042;
$whisper: #F7F8FB;

$font-family-base: 'Poppins', sans-serif;
$btn-border-radius: 8px;
$link-decoration: none;

$custom-colors: (
  primary: $primary,
  secondary: $secondary,
  maroon-flush: $maroon-flush,
  outer-space: $outer-space,
  alto: $alto,
  wood-smoke: $wood-smoke,
  bali-hai: $bali-hai,
  red-ribbon: $red-ribbon,
  danger: $danger,
  success: $success,
  warning: $warning,
  whisper: $whisper
);

$theme-colors: map-merge($theme-colors, $custom-colors);

$modal-lg: 970px;

$utilities: map-merge(
  $utilities,
  (
    "color": (
      property: color,
      class: text,
      values: map-merge(
        $theme-colors,
        $custom-colors
      )
    ),
    "background-color": (
      property: background-color,
      class: bg,
      local-vars: (
        "bg-opacity": 1
      ),
      values: map-merge(
          map-merge(
              $theme-colors,
              $custom-colors
          ),
          (
            "transparent": transparent
          )
      )
    ),
  )
);

@import "~bootstrap/scss/bootstrap";

.modal-backdrop {
  background-color: rgba($white, .5);
  //backdrop-filter: blur(30px) brightness(97%);
  backdrop-filter: blur(30px);
  z-index: 1055;

  &.show {
    opacity: 1;
  }
}

.modal-content {
  @include loader();

  &:before {
    color: $red-ribbon;
  }

  position: relative;
  box-shadow: 20px 30px 99px #0000001C;
  border-radius: rem(15);
  border: 0;
  min-width: rem(610);
  max-width: 100%;

  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 0 rem(50);
  }

  .modal-header {
    padding-top: rem(43);
    padding-bottom: rem(25);
    border-bottom: 0;
    border-radius: unset;

    .modal-title {
      font-size: rem(18);
      font-weight: 700;
      color: $outer-space;
    }

    @include media-breakpoint-down(md) {
      padding: 25px 20px 20px;
    }
  }

  .modal-body {
    padding-top: rem(32);
    padding-bottom: rem(40);

    @include media-breakpoint-down(md) {
      padding: 15px 20px 20px;
    }
  }

  .modal-footer {
    padding-top: rem(30);
    padding-bottom: rem(50);
    border-top: 0;
    border-radius: unset;
    display: flex;
    column-gap: rem(20);

    button {
      padding-left: 0;
      padding-right: 0;
      flex: 0 0 calc(50% - #{rem(10)});
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: stretch;
      column-gap: 0;
      padding: 35px 20px 25px;

      .button {
        flex: auto;
        height: 55px;

        &:last-of-type {
          order: -1;
          margin-bottom: 10px;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    min-width: unset;
  }
}

.toast {
  font-weight: 600;
}
