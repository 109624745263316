.collapse-list-box {
  --collapse-box--padding-hr: 40px;

  @include loader(40px);

  &:before {
    color: $red-ribbon;
  }

  position: relative;
  background: $white;
  box-shadow: 50px 20px 99px #8795B54D;
  border-radius: 5px;

  & + & {
    margin-top: 15px;
  }

  .collapse-fallback-msg {
    display: block;
    font-size: rem(12);
    text-align: center;
    color: rgba($outer-space, .7);
    margin: rem(35) 0 rem(25);
    font-style: italic;
  }

  .collapse-header {
    display: flex;
    align-items: center;
    padding: rem(22) var(--collapse-box--padding-hr);
    margin: 0 rem(-10);
    cursor: pointer;

    @include media-breakpoint-down(md) {
      margin: 0;
    }
  }

  .collapse-title {
    display: flex;
    align-items: center;
    font-size: rem(14);
    line-height: rem(35);
    letter-spacing: 0;
    font-weight: 600;
    color: $wood-smoke;

    &__icon {
      font-size: rem(25);
      margin-right: rem(20);
      display: inline-block;
      color: $bali-hai;
      transition: color .3s linear;
      width: 36px;
      text-align: center;
    }
  }

  .collapse-counter {
    display: inline-block;
    margin-left: auto;
    font-size: rem(14);
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0;
    color: $wood-smoke;
    padding: rem(8);
    border: 2px $secondary solid;
    border-radius: 8px;
  }

  .collapse-item-list {
    padding: 0;
    margin: rem(22) 0 rem(30);
    overflow: auto;
    max-height: 264px;

    li {
      list-style: none;
      font-size: rem(12);
      font-weight: 500;
      line-height: 1.3;
      letter-spacing: 0;
      color: $outer-space;
      padding: rem(12) rem(20);
      border-radius: 5px;
      transition: background .3s ease-out, color .3s ease-out;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &:not(:last-of-type) {
        margin-bottom: rem(2);
      }

      i {
        font-size: rem(20);
        color: $white;
      }

      &.selected {
        background: $bali-hai;
        color: $white;
      }
    }
  }

  .collapse-body {
    max-height: 0;
    overflow: hidden;
    transition: max-height .6s ease-out, opacity .6s ease-out;
    will-change: max-height, opacity;
    padding: 0 var(--collapse-box--padding-hr);
    opacity: 0;
  }

  .collapse-footer {
    padding-bottom: var(--collapse-box--padding-hr);
  }

  .collapse-submit-btn {
    font-size: rem(12);
    font-weight: 600;
    line-height: rem(17);
    letter-spacing: 0;
    color: $wood-smoke;
    width: 100%;
    padding: rem(15) 0;
    text-align: center;
    box-shadow: 0px 1px 5px #D9004233;
    border: 2px $red-ribbon solid;
    border-radius: 8px;
    transition: background .3s linear, color .2s linear, border-color .3s ease-out;

    &:hover {
      color: $white;
      background: $red-ribbon;
    }

    &[disabled] {
      border-color: $secondary;
      box-shadow: none;
    }
  }

  .form-field {
    &:before {
      height: 2px;
      top: 47px;
    }

    input {
      padding-left: rem(20);
      height: 47px;

      &::placeholder {
        color: $bali-hai;
        font-size: rem(12);
        font-weight: normal;
        text-transform: lowercase;
      }
    }
  }

  .btn-search {
    background: none;
    border: none;
    box-shadow: none;
    color: $bali-hai;
    font-weight: 600;
    margin-right: calc(20px - .75rem);
  }

  &.open {
    .collapse-title__icon {
      color: $primary;
    }

    .collapse-body {
      opacity: 1;
    }
  }

  @include media-breakpoint-down(md) {
    --collapse-box--padding-hr: 20px;
  }
}
