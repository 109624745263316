.filters-bar {
  .input-group-text,
  .form-control,
  .button {
    height: auto;
    line-height: 1;
    padding: .5rem;
  }

  .vue-input {
    padding: 0;
  }

  fieldset {
    margin-bottom: 0 !important;
  }
}
