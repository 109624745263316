.block-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background: rgba($white, .6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

