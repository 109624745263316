
.order-by-select {
  min-width: 191px;

  .multiselect {
    background: transparent;
  }

  .multiselect,
  .multiselect-dropdown .multiselect-options .multiselect-option {
    font-size: 11px;
  }

  .multiselect-single-label {
    max-width: 76%;
  }
}
