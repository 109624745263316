.dashboard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: rem(32);

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .button {
      margin-top: 15px;
    }
  }
}

.dashboard-title {
  margin-bottom: rem(5) !important;
  font-size: rem(15);
  font-weight: 600;
  color: $wood-smoke;
}

.dashboard-subtitle {
  margin-top: 0 !important;
  margin-bottom: 0;
  font-size: rem(12);
  font-weight: 500;
  letter-spacing: 0;
  color: $bali-hai;

  @include media-breakpoint-up(md) {
    margin-right: 140px;
  }
}

.red-ribbon {
  color: $red-ribbon;
}