form {
  &:not(.no-form-field-margin) {
    --form-field-margin-bottom: #{rem(43)};

    .form-field {
      margin-bottom: var(--form-field-margin-bottom);
    }
  }
}

.form-field {
  $input-height: rem(37);

  position: relative;
  width: 100%;

  &:not(.form-field--inline):not(.form-field--select):not(.form-field--check) {
    border: rem(2) rgba($bali-hai, .4) solid;
    border-radius: rem(5);
    padding: 0 rem(25);
    background: rgba($secondary, .4);

    .form-control {
      height: rem(56);
      background: transparent;

      &::placeholder {
        color: $bali-hai;
        font-size: rem(14);
        font-weight: normal;
      }
    }

    &.form-field--disabled {
      border: rem(2) rgba($bali-hai, .2) solid;
      background: rgba($secondary, .4) !important;

      .form-control {
        color: $bali-hai;
      }
    }
  }

  &--inline {
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 4px;
      top: $input-height;
      left: 0;
      background: $alto;
      z-index: 1;
      border-radius: 10px;
      transition: background .3s linear;
    }

    input {
      &::placeholder {
        color: transparent;
      }
    }

    label {
      position: absolute;
      bottom: 7px;
      left: 0;
      z-index: 4;
      margin: 0;
      opacity: .5;
      pointer-events: none;
      touch-action: none;
      user-select: none;
      text-transform: lowercase;
      transition: bottom .3s ease-out, font-size .3s linear;
      will-change: bottom, font-size;
    }

    &.focused,
    &.filled {
      label {
        bottom: 100%;
        font-size: 12px;
        pointer-events: auto;
        touch-action: auto;
        user-select: auto;
      }
    }

    &.form-field--no-label {
      input {
        &::placeholder {
          font-size: rem(14);
          font-weight: normal;
          line-height: rem(20);
          letter-spacing: 0;
          color: rgba($outer-space, .5);
        }
      }
    }
  }

  input {
    font-size: rem(16);
    line-height: rem(23);
    letter-spacing: 0;
    font-weight: bold;
  }

  label {
    padding: 0;
    font-size: rem(14);
    font-weight: normal;
    line-height: rem(20);
    letter-spacing: 0;
    color: $outer-space;
  }

  .form-control {
    height: $input-height;
    padding: 0;

    &,
    &:focus {
      border: 0;
    }

    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &[readonly] {
      background-color: initial;
      cursor: pointer;
    }
  }

  .form-text {
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    margin-top: rem(8);
    font-size: rem(12);
  }

  &.focused {
    &:before {
      background: $maroon-flush;
    }
  }

  &--check {
    label {
      font-size: rem(12);
      font-weight: 600;
    }
  }
}
