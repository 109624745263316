$itemCount: 4;

.action-container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }
}

.action-box {
  position: relative;
  width: calc(100% / #{$itemCount});
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #48C774;
  cursor: pointer;
  color: white;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    width: 100%;
    flex: 1;
  }

  &__content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0, #48c774, #27ae60);
    opacity: 0;
    transition: opacity .4s ease-in-out;
  }

  h2 {
    position: relative;
    text-align: center;
    color: #363636;
  }

  i {
    position: absolute;
    left: 23%;
    top: 50%;
    font-size: 8em;
    opacity: .3;
    transform: translate(-50%, -50%);
    transition: transform .6s ease-in-out, opacity 1s ease-in-out;
  }

  &:hover {
    &:before {
      opacity: 1;
    }

    i {
      opacity: .8;
      transform: translate(25%, -50%);
    }
  }

  &:last-of-type {
    i {
      font-size: 7em;
    }
  }
}
