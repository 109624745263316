.multiselect {
  --multiselect--padding-hr: #{rem(25)};
  --multiselect--padding-vr: #{rem(17.5)};

  border: 2px rgba($bali-hai, .4) solid;
  min-height: unset;
  padding: var(--multiselect--padding-vr) var(--multiselect--padding-hr);
  padding-left: 0;

  .custom-caret {
    i {
      font-size: rem(12);
    }
  }

  .multiselect-placeholder,
  .multiselect-single-label,
  .multiselect-multiple-label,
  .multiselect-search {
    padding-left: var(--multiselect--padding-hr);
  }

  .multiselect-search {
    background: transparent;
  }

  .multiselect-single-label,
  .multiselect-multiple-label {
    font-weight: bold;
  }

  & {
    font-size: rem(14);
    color: $wood-smoke;
  }

  &,
  &.is-open {
    border-radius: rem(5);
  }

  &.is-active {
    box-shadow: unset;
  }

  &.is-open {
    .multiselect-placeholder,
    .multiselect-single-label,
    .multiselect-multiple-label,
    .multiselect-search,
    .multiselect-clear,
    .custom-caret {
      z-index: 106;
    }
  }
}

.multiselect-dropdown {
  border: none;
  overflow-y: unset;
  background: $white;
  box-shadow: 0 20px 60px rgba($bali-hai, .66);
  border-radius: rem(5);
  bottom: unset;
  transform: unset;
  top: rem(-10);
  left: rem(-5);
  right: rem(-5);
  padding-top: rem(60);

  .multiselect-options .multiselect-option,
  .multiselect-no-options {
    padding: 0 rem(30);

    &:first-of-type {
      padding-top: rem(20);
    }

    &:last-of-type {
      padding-bottom: rem(30);
    }
  }

  .multiselect-options {
    overflow-y: auto;

    .multiselect-option {
      background: none;
      font-size: rem(12);
      font-weight: 500;
      line-height: 1.2;
      color: $outer-space;
      transition: color .3s ease-out;
      will-change: color;

      &.is-selected {
        font-weight: bold;
      }

      &:not(.is-selected).is-pointed {
        color: $bali-hai;
      }

      &:not(:last-of-type) {
        margin-bottom: rem(15);
      }
    }
  }

  .multiselect-group {
    padding: 0 rem(30);

    .multiselect-group-label {
      background: transparent;
      padding: 0;
      color: $outer-space;
      font-weight: 600;
      font-size: rem(13)
    }

    .multiselect-option {
      padding: 0 rem(15);
    }
  }
}

.form-field {
  &.form-field--inline {
    .multiselect {
      --multiselect--padding-hr: 0;
      --multiselect--padding-vr: #{rem(8)};

      border: none;

      .multiselect-placeholder {
        color: rgba($outer-space, .5);
      }

      .multiselect-dropdown {
        padding-top: rem(52);
        top: rem(-13);
        left: rem(-30);
        right: rem(-30);
      }
    }
  }
}
