.simple-table {
  position: relative;
  @include loader(35px);

  &:before {
    color: $red-ribbon;
  }

  &__row {
    display: flex;
    align-items: stretch;

    &:nth-child(even) {
      .simple-table__column {
        background: $whisper;
      }
    }
  }

  &__column {
    background: $white;
    color: $wood-smoke;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    column-gap: rem(8);
    min-height: rem(80);

    &:first-of-type {
      padding: rem(10) rem(35);
      width: rem(263);
      font-size: rem(16);
      font-weight: 600;
      border-top-left-radius: rem(5);
      border-bottom-left-radius: rem(5);
    }

    &:last-of-type {
      padding: rem(10) rem(20);
      flex: 1;
      border-top-right-radius: rem(5);
      border-bottom-right-radius: rem(5);

      > * {
        flex: 1;
      }

      .form-field:not(.form-field--inline) {
        --form-field-margin-bottom: #{rem(28)};

        background: transparent !important;

        &:not(.has-message) {
          margin-bottom: 0;
        }
      }
    }

    & + & {
      border-left: 1px $secondary solid;
    }
  }

  @include media-breakpoint-down(md) {
    &__row {
      flex-direction: column;
    }

    &__column {
      &:first-of-type {
        width: 100%;
        padding: rem(25) rem(45) 0;
        min-height: auto;
      }

      &:last-of-type {
        flex-direction: column;
        gap: 15px;

        > * {
          width: 100%;
        }
      }

      & + & {
        border-left: 0;
      }
    }
  }

  &--bordered {
    border: 1px $secondary solid;
  }
}
