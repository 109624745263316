@mixin loader($width: 60px, $modifierClass: loading, $inline: false) {
  $svgUrl: "/dist/assets/img/loader.svg";

  @if $inline {
    &.#{$modifierClass} {
      display: flex;
      align-items: center;
      justify-content: center;

      * {
        display: none;
      }

      &:before {
        content: '';
        display: block;
        width: $width;
        height: $width;
        border-radius: 50%;
        border: 3px solid currentColor;
        border-color: currentColor transparent currentColor transparent;
        animation: lds-dual-ring 1.2s linear infinite;
      }
    }
  } @else {
    &:before,
    &:after {
      opacity: 0;
      touch-action: none;
      pointer-events: none;
      transition: opacity .3s ease-out;
      will-change: opacity;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      z-index: 41;
      width: $width;
      height: $width;
      top: calc(50% - #{$width / 2});
      left: calc(50% - #{$width / 2});
      border-radius: 50%;
      border: 3px solid currentColor;
      border-color: currentColor transparent currentColor transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, .6);
      backdrop-filter: blur(2px);
      border-radius: rem(15);
      z-index: 40;
    }

    &.#{$modifierClass} {
      &:before,
      &:after {
        opacity: 1;
        touch-action: initial;
        pointer-events: auto;
      }
    }
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
