.wit-header {
  background: $white;
  box-shadow: 0 10px 50px #0000000F;
  padding: 0 rem(50);
  height: var(--global--navigation-height);

  &__brand {
    position: relative;
    z-index: 71;
    margin-right: 20px;

    img {
      max-width: 100%;
    }
  }

  > div > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button {
    margin-left: rem(25);
    padding: rem(15);

    span {
      display: none;
    }

    i {
      margin-right: 0;
    }

    & + .button {
      margin-left: rem(10);
      padding: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 0 rem(20);
  }

  @media (min-width: 1470px) {
    .button {
      margin-left: rem(50);
      padding: rem(15) rem(25);

      span {
        display: inline-block;
      }

      i + span {
        margin-left: rem(15);
      }

      & + .button {
        margin-left: rem(20);
      }
    }
  }

  @include media-breakpoint-down(xl) {
    .button {
      margin-left: 0;
    }
  }
}

.wit-navbar {
  display: flex;
  align-items: center;
  height: 100%;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    column-gap: rem(30);
    height: 100%;

    li {
      list-style: none;
    }
  }

  .nav-item {
    padding: 0;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .nav-link {
    --nav-link--padding-hr: #{rem(15)};

    font-size: rem(14);
    font-weight: 600;
    line-height: rem(20);
    padding: 0 var(--nav-link--padding-hr);
    color: $bali-hai;
    transition: color .3s ease-out;
    white-space: nowrap;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background: $red-ribbon;
      border-radius: 10px;
      opacity: 0;
      transition: opacity .3s ease-out;
    }

    i {
      font-size: rem(16);
      margin-right: rem(20);
    }

    span {
      display: inline-block;
    }

    &.active {
      color: $wood-smoke;

      &:after {
        opacity: 1;
      }
    }
  }

  .nav-badge {
    position: absolute;
    left: var(--nav-link--padding-hr);
    top: calc(50% - 20px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: $maroon-flush;
    box-shadow: 0 5px 10px #F31A4733;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    color: $white;
    font-size: rem(14);
    font-weight: 700;
    transform: translateX(-50%);
  }

  @media (min-width: 1470px) {
    ul {
      column-gap: rem(60);
    }

    .nav-link {
      --nav-link--padding-hr: 0px;
    }
  }
}

.locale-select {
  max-width: 15rem;

  .vue-input {
    padding: 6px 10px;
  }
}

.navbar-toggler {
  border: 0;
  padding: 0;
  outline: 0;
  box-shadow: none !important;
  position: relative;
  width: 22px;
  height: 15px;

  span {
    position: absolute;
    width: 22px;
    height: 2px;
    left: 0;
    display: block;
    border-radius: 25px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    background-color: white;
  }
}

.navbar-toggler span:first-of-type {
  top: 0
}

.navbar-toggler span:nth-of-type(2),
.navbar-toggler span:nth-of-type(3) {
  top: 6px
}

.navbar-toggler span:nth-of-type(4) {
  top: 12px
}

.navbar-toggler[aria-expanded="true"] span:first-of-type {
  top: 6px;
  width: 0;
  left: 50%
}

.navbar-toggler[aria-expanded="true"] span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.navbar-toggler[aria-expanded="true"] span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.navbar-toggler[aria-expanded="true"] span:nth-child(4) {
  top: 6px;
  width: 0;
  left: 50%
}

.locale-select {

  i {
    color: #fff;
  }

  .vue-select {
    border: 0;
    width: 100px;

    .icon.arrow-downward {
      color: #fff;
      border-color: #fff transparent transparent
    }

    input::placeholder {
      color: #fff;
    }

    .vue-dropdown {
      border: 1px solid rgba(0, 0, 0, 0.15);
    }

    .vue-dropdown-item {
      padding: 5px 12px;

      &.selected {
        background-color: #fff;
        //color: $primary;
      }

      &.highlighted {
        background-color: #e9ecef;
        color: #000;
      }
    }
  }
}

.profile-dropdown {
  margin-right: 30px;
  //margin-top: 6px;

  .dropdown-toggle {
    color: #fff;

    &:after, &:before {
      display: none;
    }
  }

  @include media-breakpoint-up(xl) {
    margin-right: 0;
  }

  svg {
    color: #000;
  }
}

.card-top {
  background-color: $primary;
  padding: 30px 0;

  .card-title {
    color: #fff;
    margin-bottom: 0;
  }

  p {
    color: #fff;
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 14px;
  }
}
