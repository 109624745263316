
@import "@/assets/style/helpers/functions";

:deep(.divided-layout__content__left-col) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .button {
    font-size: 11px;
    margin-top: 38px;
  }

  a:not(.button) {
    text-decoration: underline;
    margin-top: 32px;
  }
}

:deep(.divided-layout__content__right-col) {
  padding-bottom: rem(170);
}
