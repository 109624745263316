
.form-check {
  a:not(.button) {
    font-size: inherit;
    color: inherit;
    text-decoration: underline;
  }
}

:deep(.form-field--check) {
  margin-top: 11px;
}
