.mobile-menu {
  height: 100%;
  display: flex;
  align-items: center;

  @include media-breakpoint-down(xxl) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: auto;
    background: $white;
    z-index: 70;
    transform: translateX(100%);
    justify-content: center;
    flex-direction: column;
    transition: transform .4s cubic-bezier(0.4, 0, 0.2, 1);

    &.opened {
      transform: translateX(0);
    }

    .navbar-actions {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }

    .wit-navbar {
      height: auto;

      ul {
        flex-direction: column;
        column-gap: 0;
      }

      .nav-item {
        height: auto;

        &:not(:last-of-type) {
          margin-bottom: 50px;
        }

        .nav-link {
          &:after {
            bottom: -20px;
          }
        }
      }
    }
  }
}
