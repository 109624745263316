.list-container {
  position: relative;
  @include loader(35px);

  &:before {
    color: $red-ribbon;
  }

  &.loading {
    table {
      border-top: none !important;
    }
  }
}

.list-top-actions {
  margin-bottom: rem(37);

  h6 {
    font-size: rem(15);
    font-weight: 600;
    color: $wood-smoke;
    line-height: 1;
    margin: 0;
    margin-bottom: rem(5);

    & + p {
      font-size: rem(12);
      color: $bali-hai;
      margin: 0;

      strong {
        font-weight: 500;
      }
    }
  }
}

.list-bottom-actions {
  margin-top: 1rem;
}

.list-row-id {
  width: 1%;
  word-break: keep-all;
  white-space: nowrap;
}

.list-row-actions {
  width: 1%;
  word-break: keep-all;
  white-space: nowrap;
  text-align: right;

  button {

    &:not(:last-child) {
      margin-right: 0.3rem;
    }
  }

  .btn-group {

    &:not(:last-child) {
      margin-right: 0.3rem;
    }

    button {
      margin-right: 0;
    }
  }
}

.list-details {
  padding: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
  position: relative;

  .btn-collapse {
    margin: 0 !important;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 32px !important;
    height: 32px !important;
    border-radius: 50%;
    border: 1px #eee solid;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;

    i {
      margin-bottom: -3px;
    }
  }

  > div {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-in-out, line-height 0.3s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
    line-height: 0;

    > div {
      padding: 0.5rem 0.5rem;
    }
  }

  &.show {
    > div {
      max-height: 500px;
      opacity: 1;
      line-height: 1;
    }
  }
}

.dropdown-toggle-split {
  border: 0;

  @include button-hover {
    background-color: #eeeeee !important;
  }

  &.show {
    background-color: #eeeeee !important;
  }

  svg {
    width: 18px;
    fill: #c7c7c7;
  }

  &:after, &:before {
    display: none !important;
  }
}

.table > :not(:first-child) {
  border-top: none;
}

table {
  border-collapse: separate;
  border-spacing: 0 1px;
  border-top: 1px rgba($bali-hai, .49) solid !important;
  border-radius: 0;

  tr {
    background: transparent;
    border: 0 !important;

    td {
      font-size: rem(12);
      font-weight: 600;
      line-height: rem(17);
      color: $bali-hai;
      padding: rem(21) rem(15) !important;
      background-color: $white !important;
      border: 0 !important;

      &:first-child {
        padding-left: rem(30) !important;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        padding-right: rem(30) !important;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &.row-prefix {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    &:nth-child(even) {
      td {
        background-color: rgba($white, .72) !important;
      }
    }
  }

  thead {
    tr {
      th {
        font-size: rem(12);
        font-weight: normal;
        color: $bali-hai;
        border: 0 !important;
        white-space: nowrap;

        &:not(.check-column) {
          padding: rem(26) rem(15) 0 !important;
        }

        &:first-child {
          padding-left: rem(30) !important;
        }

        &:last-child {
          padding-right: rem(30) !important;
        }

        &.check-column {
          padding: 0 0 0 15px !important;
        }
      }
    }
  }

  tbody {
    &:before {
      content:"@";
      display:block;
      line-height: rem(26);
      text-indent:-99999px;
    }

    tr {
      &:hover {
        position: relative;
        box-shadow: 0 20px 60px #8795B566 !important;

        td {
          background-color: $white !important;

          button:not([disabled]) {
            color: $wood-smoke !important;
          }
        }
      }
    }
  }
}

table {
  tr {
    th,
    td {
      &.check-column {
        padding: 0 0 0 15px !important;
      }
    }
  }
}

@media(max-width: 767px) {

  table {
    display: block;
    overflow: visible;

    &::-webkit-scrollbar {
      display: none;
    }

    thead, tbody, tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    th {
      border-top: 0;
    }

    tr {
      border: 1px solid #ccc;
      border-left: 0;
      border-right: 0;

      &::-webkit-scrollbar {
        display: none;
      }

      &:first-child {
        border-top: 0;
      }

      &:last-child {
        border-bottom: 0;
      }

      &:not(:last-of-type) {
        border-bottom: 0;
      }
    }

    td {
      border: none;
      border-bottom: 1px solid #eee !important;
      position: relative;
      padding-left: 50% !important;
      //padding-left: 12px !important;
      width: auto !important;
      font-size: 12px;
      white-space: initial !important;
      padding-right: 12px;
      text-align: left !important;
      word-break: break-word;
      min-width: auto !important;
      display: block;

      &.list-row-actions {
        &:before {
          display: none;
        }
      }

      &:before {
        content: attr(data-label);
        width: 100%;
        min-width: 50%;
        padding-right: 10px;
        padding-bottom: 5px;
        text-align: left;
        display: block;
        font-weight: 700;
      }
    }
  }
}
