.custom-icon {
  display: inline-block;
  width: 16px;
}
.custom-icon path {
  fill: currentColor;
  transition: fill 0.3s ease-out, stroke 0.3s ease-out;
  will-change: fill, stroke;
}

.custom-icon-check path,
.custom-icon-plus path,
.custom-icon-pencil path,
.custom-icon-layout path,
.custom-icon-user path,
.custom-icon-trash path,
.custom-icon-users path {
  stroke: currentColor;
  fill: transparent;
}

.custom-icon-doctor {
  width: 14px;
}