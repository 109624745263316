[class*="css-icon-chevron"] {
  --chevron-size: #{rem(3.5)};
  --chevron-border: #{rem(2)};

  color: inherit;
  border-style: solid;
  border-color: currentColor;
  border-width: 0 var(--chevron-border) var(--chevron-border) 0;
  display: inline-block;
  padding: var(--chevron-size);
}

.css-icon-chevron-right {
  transform: rotate(-45deg);
  margin-left: calc(var(--chevron-border) * -1);
}

.css-icon-chevron-left {
  transform: rotate(135deg);
  margin-left: var(--chevron-size);

  & + & {
    margin-left: calc(var(--chevron-border) * -1);
  }
}

.css-icon-chevron-up {
  transform: rotate(-135deg);
}

.css-icon-chevron-down {
  transform: rotate(45deg);
}
