<template>
  <main class="not-found-main d-flex flex-column align-items-center justify-content-center">
    <h1 class="not-found-404">{{ $t('page.not_found') }}</h1>
    <p>{{ $t('auth.label_not_found', { path: path }) }}</p>
    <div class="d-flex gap-2 mt-5">
      <button
        class="btn btn-primary"
        @click="back">
        {{ $t('common.btn_back') }}
      </button>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import ROUTES from '@/router/routes';

export default defineComponent({
  name: 'NotFoundPage',

  props: {
    path: String,
  },

  setup() {
    const router = useRouter()
    const back = () => router.replace(`/${ROUTES.DASHBOARD}`)

    return {
      back
    }
  }

})
</script>

<style lang="css" scoped>
  .not-found-main {
    height: 600px;
  }

  .not-found-404 {
    font-size: 7.5rem;
    color: #5d5d5d;
  }
</style>
