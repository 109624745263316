.card {
  background: $white;
  box-shadow: 50px 20px 99px #8795B54D;
  border-radius: 5px;

  &__header,
  &__footer {
    padding: rem(20) rem(40);

    @include media-breakpoint-down(md) {
      padding: 10px 20px;
    }
  }

  &__header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &__body {
    padding: rem(40);

    @include media-breakpoint-down(md) {
      padding: 20px;
    }
  }

  &__footer {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  & + & {
    margin-top: rem(17);
  }
}
