.order-by-select {
  min-width: 191px;
}
.order-by-select .multiselect {
  background: transparent;
}
.order-by-select .multiselect,
.order-by-select .multiselect-dropdown .multiselect-options .multiselect-option {
  font-size: 11px;
}
.order-by-select .multiselect-single-label {
  max-width: 76%;
}