.center-box {
  background: $white;
  border-radius: 15px;
  box-shadow: 20px 30px 99px #0000001C;
  padding: 40px 30px 35px;
  max-width: 340px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
  }

  &__body {
    padding-top: 30px;
    flex: 1;
    display: flex;
    flex-direction: column;

    a:not(.button) {
      text-decoration: underline;
    }
  }

  &--lg {
    max-width: 650px;
  }

  @include media-breakpoint-down(md) {
    padding: 30px 20px 25px;
  }
}
