@function card-width($cardPerRow) {
  @return calc(100% / #{$cardPerRow} - #{$cardPerRow - 1} * #{rem(40)} / #{$cardPerRow});
}

.my-times-list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: rem(40);

  .my-time-card {
    min-width: unset;
    width: card-width(1);

    @media (min-width: 768px) {
      width: card-width(2);
    }

    @media (min-width: 1360px) {
      width: card-width(3);
    }

    @media (min-width: 1700px) {
      width: card-width(4);
    }
  }
}

.my-time-card {
  position: relative;
  background: $white;
  border-radius: 5px;
  overflow: hidden;
  min-width: 420px;
  box-shadow: 50px 20px 99px #8795B54D;

  @include loader(35px);

  &:before {
    color: $red-ribbon;
  }

  &__header {
    background: $whisper;
    border-bottom: 1px rgba($bali-hai, .49) solid;
    padding: rem(21) rem(31);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      padding: 15px 20px;
    }
  }

  &__body {
    padding: rem(32) rem(32) rem(36);

    @include media-breakpoint-down(md) {
      padding: 15px 15px 20px;
    }
  }

  &__footer {
    border-top: 1px rgba($bali-hai, .49) solid;
    padding: rem(20) rem(30) rem(20) rem(40);
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: rem(91);
    font-size: rem(14);

    @include media-breakpoint-down(md) {
      padding: 12px 20px 20px 30px;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;

      .button {
        width: 100%;
        text-align: center;

        &:last-of-type {
          order: -1;
          margin-bottom: 15px;
        }
      }
    }
  }

  &__date {
    color: $wood-smoke;
    line-height: 1;

    strong {
      display: block;
      font-weight: 600;
      font-size: rem(14);
    }

    span {
      font-size: rem(12);
    }
  }

  &__time {
    font-weight: 600;
    font-size: rem(30);
    color: $wood-smoke;
  }

  &__today-mark {
    padding: rem(5) rem(7);
    background: $red-ribbon;
    color: $white;
    font-size: rem(14);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: rem(16);
    border-radius: 5px;
  }

  &--placeholder {
    background: transparent;
    box-shadow: none;
    border: 2px dashed rgba($bali-hai, .6);
    padding: 35px 47px 47px;
    margin-top: 25px;

    .my-time-card__title {
      line-height: rem(38);
      font-size: rem(30);
      color: $bali-hai;
      font-weight: 600;

      & + p {
        margin-top: rem(43);
      }
    }

    p {
      font-size: rem(12);
      color: $bali-hai;
      margin-bottom: 0;

      & + .button {
        margin-top: rem(123);
      }
    }

    @include media-breakpoint-down(md) {
      padding: 15px 23px 23px;
    }
  }
}
