:root {
  --global--navigation-height: 100px;
}

* {
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba($secondary, .9);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #F31A47;
    border-radius: 5px;
    width: 1px;
  }
}

html,
body,
#app {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  background: $secondary;
}

a {
  font-size: 14px;
}

#app {
  display: flex;
  flex-direction: column;
}

@mixin button-hover {
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    @content;
  }
}
