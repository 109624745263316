.time-summary {
  &,
  &__list,
  &__list__item {
    display: flex;
    align-items: center;
  }

  &,
  &__list {
    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;

      .time-summary__list__item__icon {
        width: 52px;
        text-align: center;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;

    &__item {
      list-style: none;

      i {
        font-size: rem(25);
        margin-right: rem(20);
        color: $primary;
      }

      span {
        font-size: rem(14);
        font-weight: 600;
        color: $wood-smoke;
        margin-top: 3px;
      }

      &:not(:last-of-type) {
        margin-right: rem(70);

        @include media-breakpoint-down(md) {
          margin-right: 0;
        }
      }
    }
  }

  &__submit {
    margin-left: auto;
    box-shadow: none;
    font-size: rem(12);
    line-height: rem(17);

    i {
      margin-right: 0;
      margin-left: rem(38);
    }
  }

  &--horizontal {
    .time-summary {
      &,
      &__list {
        flex-direction: column;
        align-items: flex-start;
      }

      &__list__item {
        flex-direction: row;
        align-items: center;

        &__icon {
          width: 52px;
          text-align: center;
        }

        &:not(:last-of-type) {
          margin-right: 0;
          margin-bottom: rem(20);
        }
      }
    }
  }
}
