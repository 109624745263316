.calendar {
  --calendar--hr-padding: 40px;

  position: relative;
  background: $white;
  border-radius: 5px;
  overflow: hidden;

  &:focus-visible {
    outline: none;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(34) var(--calendar--hr-padding);

    &__year {
      margin: 0;
      font-size: rem(12);
      letter-spacing: 1.44px;
      color: $bali-hai;
      text-align: center;
    }

    &__month {
      margin: 0;
      font-size: rem(18);
      font-weight: 500;
      color: $wood-smoke;
      letter-spacing: 0;

      @include media-breakpoint-down(md) {
        font-size: rem(14);
        text-align: center;
      }
    }

    @include media-breakpoint-down(md) {
      padding: rem(14) var(--calendar--hr-padding);
    }
  }

  &__body {
    background: #F3F5F9;
    display: flex;
    align-items: stretch;
    border-top: 1px $secondary solid;
    margin-bottom: 15px;
    overflow-x: auto;

    &__inner {
      display: flex;
      align-items: stretch;
      width: 100%;
      min-width: 690px;
      overflow: auto;
    }
  }

  &__month-body {
    padding: rem(12) var(--calendar--hr-padding);
    background: $white;
    border-top: 0;
    flex-direction: column;
    height: auto;
    min-height: 390px;
    max-height: unset;
    margin-bottom: 0;

    .calendar__body__inner {
      flex-direction: column;
    }

    .calendar-month {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      border: 1px $secondary solid;
      border-right: 0;
      border-bottom: 0;
    }

    .calendar-week-days {
      display: flex;
      align-items: center;
      margin-bottom: rem(24);

      .calendar-week-day {
        flex: 1;
        text-transform: capitalize;
        text-align: center;
        font-size: rem(20);
        font-weight: 600;
        color: $wood-smoke;
      }
    }
  }

  &__footer {
    min-height: 35px;
  }

  &__month-day {
    position: relative;
    flex: 1 1 calc(100% / 7);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px $secondary solid;
    border-top: 0;
    border-left: 0;
    padding: rem(25);
    cursor: pointer;
    min-height: 80px;

    .calendar-day-header {
      margin-bottom: 0;
    }

    .calendar-day-title {
      font-size: rem(20);
      font-weight: 600;
      color: $wood-smoke;
      margin-bottom: 0;
      width: auto;
    }

    .calendar-fullness-mark {
      width: 50px;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
    }

    &.disabled,
    &.is-past {
      pointer-events: none;
      user-select: none;
      touch-action: none;
    }

    &.disabled {
      background: #F3F5F9;

      .calendar-day-title {
        color: rgba($bali-hai, .3);
        font-weight: normal;
      }
    }

    &.is-past {
      .calendar-day-title {
        color: rgba($wood-smoke, .2);
        font-weight: normal;
      }
    }

    &.active {
      border: 3px $red-ribbon solid;
      box-shadow: 0 1px 5px #D9004233;
    }
  }

  &__week-day {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 16px 0 20px;

    &:not(:last-of-type) {
      border-right: 1px $secondary solid;
    }
  }

  @include media-breakpoint-down(md) {
    --calendar--hr-padding: 20px;
  }

  @include media-breakpoint-down(sm) {
    margin: 0 calc(var(--divided-layout--content-hr-padding) * -1);
  }
}

.calendar-day-title {
  color: $bali-hai;
  font-size: 30px;
  letter-spacing: 1.5px;
  line-height: 1;
  margin-bottom: 8px;
}

.calendar-day-subtitle {
  color: $wood-smoke;
  font-size: 14px;
  letter-spacing: 0;
  font-weight: 500;
  margin-bottom: 0;
}

.calendar-time-list {
  margin: 0;
  padding: 0 13px 3px;
  width: 100%;
  height: 274px;
  max-height: 274px;
  overflow: auto;
}

.calendar-time-list-item {
  list-style: none;
  margin: 0;
  padding: 14px 25px;
  font-size: 13px;
  font-weight: 500;
  color: $bali-hai;
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  background: $white;
  width: 100%;
  max-width: 100%;
  border-radius: 5px;
  box-shadow: 0px 1px 0px #8795B538;
  cursor: pointer;
  border: 2px transparent solid;
  transition: border-color .3s ease-out;
  user-select: none;

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }

  &.selected {
    border-color: $red-ribbon;
  }
}

.calendar-day-header {
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.calendar-nav {
  border: 2px $secondary solid;
  border-radius: 8px;
  padding: rem(11) rem(12);
  min-height: unset;

  i {
    margin: 0;
    font-size: rem(13);
    -webkit-text-stroke: 1px $bali-hai;
    line-height: 1;
    color: $bali-hai;
  }

  &[disabled] {
    i {
      color: rgba($bali-hai, .3);
      -webkit-text-stroke: 1px rgba($bali-hai, .3);
    }
  }
}

.calendar-fullness-mark {
  display: inline-block;
  width: 25px;
  height: 5px;
  border-radius: 3px;
}

.calendar-hint-list {
  margin: rem(50) 0 0 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.calendar-hint-item {
  list-style: none;
  font-size: rem(15);
  color: $bali-hai;
  display: flex;
  align-items: center;

  .calendar-fullness-mark {
    vertical-align: middle;
    margin-right: rem(20);
  }

  &:not(:last-of-type) {
    margin-right: rem(60);
  }
}
