footer {
    font-size: 13px;
    color: #999;
    padding: 15px 0;
    box-shadow: 0 0 10px rgb(0, 0, 0, 0.05);
    background-color: $gray-600;

    @include media-breakpoint-down(md) {

        .d-flex {
            flex-wrap: wrap;
            justify-content: center;

            > div {
                flex: 0 0 100%;
                text-align: center;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}


