.login-main {
  height: 100vh;
  min-height: 650px;

  .logo-wrapper {
    margin-bottom: 65px;

    span {
      font-size: 12px;
      margin-left: 10px;
    }
  }

  .login-title {
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 30px;
    text-align: center;
  }

  @include media-breakpoint-down(md) {
    justify-content: flex-start !important;
    padding: 50px 20px 70px;
    height: auto;
  }
}
