.pagination {
  margin: 0;
  display: flex;
  align-items: center;

  li {
    &,
    a {
      color: $wood-smoke;
    }

    &.disabled {
      pointer-events: none;

      &,
      a {
        color: $bali-hai;
      }
    }

    &:not(.pagination__button) {
      font-size: rem(14);
      font-weight: 600;

      &,
      a {
        color: $bali-hai;
      }

      &:not(:last-of-type) {
        margin-right: 15px;
      }

      &.active {
        &,
        a {
          color: $wood-smoke;
        }
      }
    }
  }

  &__button {
    $size: rem(48);

    width: $size;
    height: $size;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #8795B54D;
    font-size: rem(13);
  }
}
