.divided-layout {
  --divided-layout--footer-height: 0px;
  --divided-layout--top-bar-height: 0px;
  --divided-layout--content-hr-padding: 60px;

  @include media-breakpoint-down(xl) {
    --divided-layout--content-hr-padding: 40px;
  }

  @include media-breakpoint-down(lg) {
    --divided-layout--content-hr-padding: 20px;
  }

  flex-direction: column;
  position: relative;

  h5,
  h6 {
    font-weight: 600;

    & + * {
      margin-top: rem(45);
    }
  }

  h5 {
    font-size: rem(18);
  }

  h6 {
    font-size: rem(15);
  }

  * + h6 {
    margin-top: rem(60);
  }

  &,
  &__content {
    display: flex;
    flex: 1;
  }

  &__backdrop {
    position: absolute;
    display: none;
    top: var(--divided-layout--top-bar-height);
    left: 0;
    right: 0;
    bottom: var(--divided-layout--footer-height);
    background: rgba($wood-smoke, .6);
    backdrop-filter: blur(5px);
    pointer-events: none;
    touch-action: none;
    opacity: 0;
    transition: opacity .4s;
    will-change: opacity;
    z-index: 2;
  }

  &__top-bar {
    padding: 0 49px 0;
    height: var(--divided-layout--top-bar-height);
    display: none;
    align-items: center;
    background: $white;
    border-top: 1px rgba($wood-smoke, .1) solid;

    i {
      font-size: 25px;
      vertical-align: middle;
    }

    @include media-breakpoint-down(xl) {
      padding: 0 40px 0;
    }

    @include media-breakpoint-down(lg) {
      padding: 0 20px 0;
    }
  }

  &__content {
    &__left-col {
      width: 460px;
      max-height: calc(100vh - var(--global--navigation-height) - var(--divided-layout--footer-height));
      backdrop-filter: blur(30px) brightness(104%);
      padding: rem(40) rem(49) rem(60);
      transition: transform .3s ease-out;
      z-index: 43;
      overflow: auto;

      .button {
        font-size: rem(14);
      }

      @include media-breakpoint-down(xl) {
        padding: rem(30) rem(35) rem(40);
        width: 35vw;
        min-width: 335px;
      }

      @include media-breakpoint-down(lg) {
        padding: rem(40) rem(20) rem(30);
      }

      @include media-breakpoint-down(md) {
        min-width: 0;
      }
    }

    &__right-col {
      flex: 1;
      padding: rem(40) var(--divided-layout--content-hr-padding) rem(60);
      max-height: calc(100vh - var(--global--navigation-height) - var(--divided-layout--footer-height));
      overflow: auto;

      @include media-breakpoint-down(xl) {
        padding: rem(30) var(--divided-layout--content-hr-padding) rem(40);
      }

      @include media-breakpoint-down(lg) {
        padding: rem(40) var(--divided-layout--content-hr-padding) rem(30);
      }

      @include media-breakpoint-down(sm) {
        padding: rem(40) var(--divided-layout--content-hr-padding) rem(80);
      }
    }

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }

  &__footer {
    padding: 0 rem(50) 0 rem(62);
    height: var(--divided-layout--footer-height);
    background: $white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;

    > * {
      height: 100%;
    }

    @include media-breakpoint-down(xl) {
      padding: 0 var(--divided-layout--content-hr-padding) 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 20px var(--divided-layout--content-hr-padding) 25px;

      .button {
        width: 100%;

        &:last-of-type {
          order: -1;
          margin-bottom: 15px;
        }
      }
    }
  }

  &:not(.divided-layout--float) {
    .divided-layout__content__left-col {
      @include media-breakpoint-down(lg) {
        width: 100%;
        max-width: 100%;
        min-width: unset;
        backdrop-filter: unset;
      }
    }
  }

  &--float {
    --divided-layout--top-bar-height: 50px;

    .divided-layout__backdrop {
      display: block;
    }

    .divided-layout__top-bar {
      @include media-breakpoint-down(lg) {
        display: flex;
      }
    }

    .divided-layout__content__left-col {
      @include media-breakpoint-down(lg) {
        position: absolute;
        top: var(--divided-layout--top-bar-height);
        max-height: calc(100vh - var(--global--navigation-height) - var(--divided-layout--footer-height) - var(--divided-layout--top-bar-height));
        left: 0;
        bottom: 0;
        background: $secondary;
        transform: translateX(-100%);
        transition: transform .4s;
        will-change: transform;
        width: 70%;
      }

      @include media-breakpoint-down(md) {
        width: 80%;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    .divided-layout__content__right-col {
      @include media-breakpoint-down(lg) {
        max-height: calc(100vh - var(--global--navigation-height) - var(--divided-layout--footer-height) - var(--divided-layout--top-bar-height));
      }
    }

    &.show {
      @include media-breakpoint-down(lg) {
        .divided-layout__backdrop {
          opacity: 1;
          pointer-events: auto;
          touch-action: auto;
        }

        .divided-layout__content__left-col {
          transform: translateX(0);
        }
      }
    }
  }

  &--has-footer {
    --divided-layout--footer-height: 120px;

    @include media-breakpoint-down(sm) {
      --divided-layout--footer-height: auto;
    }
  }
}
