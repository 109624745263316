.form-field-group {
  position: relative;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px rgba($bali-hai, .49) solid;

  &__label {
    color: $wood-smoke;
    font-weight: 600;
    font-size: rem(12);
    line-height: 1.3;
    width: 40%;
    padding-top: rem(10);
    padding-right: rem(10);
    padding-bottom: rem(11);
  }

  &__fields {
    position: absolute;
    width: 60%;
    right: 0;
    bottom: calc(var(--form-field-margin-bottom) * -1 + 1px);
    display: flex;

    > * + * {
      margin-left: rem(20);
    }
  }

  & + * {
    margin-top: rem(30);
  }

  @include media-breakpoint-down(md) {
    --form-field-margin-bottom: 0px;

    flex-direction: column;
    border-bottom: none;

    .form-field-group__fields {
      position: static;
      bottom: unset;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;

      > * + * {
        margin-left: 0;
        margin-top: rem(30);
      }
    }
  }
}
