.menu-toggle {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  z-index: 71;

  .line {
    fill: none;
    stroke: black;
    stroke-width: 4;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
                stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 4;
  }

  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 4;
  }

  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 4;
  }

  &.opened {
    .line1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 4;
    }

    .line2 {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
      stroke-width: 4;
    }

    .line3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 4;
    }
  }
}
