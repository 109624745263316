
p {
  font-size: 14px;
}

.actions {
  display: flex;
  column-gap: 10px;
  margin-top: 20px;

  :deep(.button) {
    flex: 1 1 auto;
  }

  @media screen and (max-width: 768px){
    flex-direction: column;
    column-gap: 0;
    row-gap: 10px;
  }
}
