.double-box-container {
  border: 2px solid rgba(#8795B5, .4);
  border-radius: 15px;
  padding: 21px;
  display: flex;
  align-items: stretch;
  column-gap: 20px;
  width: 742px;
  max-width: 100%;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    column-gap: 0;
    row-gap: 20px;
    padding: 0;
    border: none;

    .center-box {
      max-width: unset;
    }
  }
}
