
.custom-icon {
  display: inline-block;
  width: 16px;

  path {
    fill: currentColor;
    transition: fill .3s ease-out, stroke .3s ease-out;
    will-change: fill, stroke;
  }
}

.custom-icon-check,
.custom-icon-plus,
.custom-icon-pencil,
.custom-icon-layout,
.custom-icon-user,
.custom-icon-trash,
.custom-icon-users {
  path {
    stroke: currentColor;
    fill: transparent;
  }
}

.custom-icon-doctor {
  width: 14px;
}
