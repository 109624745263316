
.mobile-fallback {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 35px;
  text-align: center;

  &__inner {
    max-width: 400px;
    text-align: center;
  }

  img {
    margin-bottom: 48px;
  }
}
