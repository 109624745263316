:deep(.divided-layout__content__left-col) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
:deep(.divided-layout__content__left-col) .button {
  font-size: 11px;
  margin-top: 38px;
}
:deep(.divided-layout__content__left-col) a:not(.button) {
  text-decoration: underline;
  margin-top: 32px;
}

:deep(.divided-layout__content__right-col) {
  padding-bottom: 10.625rem;
}